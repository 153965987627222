input[type="text"] {
    color: rgb(35 122 255);
    border-color:rgb(255, 255, 255);
    margin: 3px;
}
input[type="text"]:focus {
    border-color: aquamarine !important;
}
button{
    border-color:aquamarine;
    color: darkcyan;
  
    border-radius: 2px;
    background: rgb(34,193,195);
background: linear-gradient(218deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
}
::placeholder {
    color: rgb(139, 186, 255);
   
  }

button:active, button:focus {
    outline: none !important;
  }
  button::-moz-focus-inner {
    border: 0 !important;
  }
  input {outline:none;}
input:active {outline:none;}
