.time{
    color: cadetblue;
    border: 2px solid;
}
.ico_present_wind {
    background: rgba(0, 0, 0, 0) url(./images/i_wind.png) no-repeat;
    background-size: contain;
    width: 29px;
    height: 33px;
    display: table-cell;
    position: absolute;
    left: 43%;
    top: 26%;
    
}
.ico_present {
    background: rgba(0, 0, 0, 0) url(./images/wing.png) no-repeat;
    background-size: contain;
    width: 29px;
    height: 33px;
    display: table-cell;
    position: absolute;
    left: 43%;
    top: 26%;
}
.ico_sunset {
    background: rgba(0, 0, 0, 0) url(./images/sunset.png) no-repeat;
    background-size: contain;
    width: 40px;
    height: 33px;
    display: table-cell;
    position: absolute;
    left: 43%;
    top: 66%;
}
.ico_sunrise {
    background: rgba(0, 0, 0, 0) url(./images/sunrise.png) no-repeat;
    background-size: contain;
    width: 36px;
    height: 33px;
    display: table-cell;
    position: absolute;
    left: 44%;
    top: 42%;
}